import { Suspense, lazy } from "react";
import "./App.css";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Login from "./Component/Login";
// import SupervisorLogin from "./Component/SupervisorLogin";
// import Dashboard from "./Pages/dashboard/Dashboard";
// import Generalmaster from "./Pages/generalmaster/Generalmaster";
// import Projectmanagement from "./Pages/Projectmanagement/Projectmanagement";
// import Addtask from "./Pages/AddTask/Addtask";
// import Projectdetails from "./Pages/ProjectDetails/Projectdetails";
// import AllProject from "./Pages/Projectmanagement/AllProject";
// import Reportsmain from "./Pages/Reports/Reportsmain";
// import ProjectSummary from "./Pages/Reports/ProjectSummary";
// import Supervisor from "./Pages/Supervisor/Supervisor";
// import Assignmenthistory from "./Pages/Supervisor/Assignmenthistory";
// import FutureAssignments from "./Pages/Supervisor/FutureAssignments";
// import ProjectDetail from "./Pages/ProjectDetails/Projectdetails";
// import Header from "./Component/Header/Header";
// import ReportsView from "./Pages/Reports/ReportsView";
// import Notfound from "./Error/Notfound";
// import Auth from "./Auth";
// import AuthReport from "./AuthReport";

import Loading from "./Component/Loading";
import Loading2 from "./Component/Loading2";
const Login = lazy(() => import("./Component/Login"));
const SupervisorLogin = lazy(() => import("./Component/SupervisorLogin"));
const Dashboard = lazy(() => import("./Pages/dashboard/Dashboard"));
const Generalmaster = lazy(() => import("./Pages/generalmaster/Generalmaster"));
const Projectmanagement = lazy(() =>
  import("./Pages/Projectmanagement/Projectmanagement")
);
const Addtask = lazy(() => import("./Pages/AddTask/Addtask"));
const Reportsmain = lazy(() => import("./Pages/Reports/Reportsmain"));
const ProjectSummary = lazy(() => import("./Pages/Reports/ProjectSummary"));
const Supervisor = lazy(() => import("./Pages/Supervisor/Supervisor"));
const Assignmenthistory = lazy(() =>
  import("./Pages/Supervisor/Assignmenthistory")
);
const FutureAssignments = lazy(() =>
  import("./Pages/Supervisor/FutureAssignments")
);
const ProjectDetail = lazy(() =>
  import("./Pages/ProjectDetails/Projectdetails")
);
const ReportsView = lazy(() => import("./Pages/Reports/ReportsView"));
const Notfound = lazy(() => import("./Error/Notfound"));
const Auth = lazy(() => import("./Auth"));
const AuthReport = lazy(() => import("./AuthReport"));

function App() {
  return (
    <Router>
      <CssBaseline />
      {/* <Loading /> */}
      <Suspense fallback={<Loading2 />}>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/dashboard"
            element={<Auth allowTo={["ADMIN"]} Component={Dashboard} />}
          />
          <Route
            path="/general-master"
            element={<Auth allowTo={["ADMIN"]} Component={Generalmaster} />}
          />
          <Route
            path="project-summary"
            element={<Auth allowTo={["ADMIN"]} Component={ProjectSummary} />}
          />

          <Route path="view-report/:projectId">
            <Route
              path=""
              element={<Auth allowTo={["ADMIN"]} Component={Reportsmain} />}
            />
          </Route>

          <Route path="project-management">
            <Route
              path=""
              element={
                <Auth allowTo={["ADMIN"]} Component={Projectmanagement} />
              }
            />
            <Route
              path="add-project"
              element={<Auth allowTo={["ADMIN"]} Component={Addtask} />}
            />
            <Route
              path=":projectid"
              element={<Auth allowTo={["ADMIN"]} Component={ProjectDetail} />}
            />
            <Route path=":projectid/supervisor" element={<Supervisor />} />
            <Route
              path=":projectid/supervisor/assignment-history"
              element={
                <Auth
                  allowTo={["ADMIN", "SUPERVISOR"]}
                  Component={Assignmenthistory}
                />
              }
            />
            <Route
              path=":projectid/supervisor/assignment-future"
              element={
                <Auth
                  allowTo={["ADMIN", "SUPERVISOR"]}
                  Component={FutureAssignments}
                />
              }
            />
          </Route>
          <Route
            path="/reportview"
            element={<Auth allowTo={["ADMIN"]} Component={ReportsView} />}
          />
          <Route
            path="/reportview/:projectId"
            element={<Auth allowTo={["ADMIN"]} Component={Reportsmain} />}
          />
          <Route
            path="/project-report/:token"
            element={<AuthReport Component={Reportsmain} />}
          />
          <Route path="supervisor">
            <Route path="login" element={<SupervisorLogin />} />
            <Route
              path="view-assignments/:projectid"
              element={
                <Auth
                  allowTo={["ADMIN", "SUPERVISOR"]}
                  Component={Supervisor}
                />
              }
            />
            <Route
              path="view-assignments/:projectid/history"
              element={
                <Auth
                  allowTo={["ADMIN", "SUPERVISOR"]}
                  Component={Assignmenthistory}
                />
              }
            />
            <Route
              path="view-assignments/:projectid/future"
              element={
                <Auth
                  allowTo={["ADMIN", "SUPERVISOR"]}
                  Component={FutureAssignments}
                />
              }
            />
          </Route>
          {/* <Route path="/projectdetails" element={<Projectdetails />} /> */}
          {/* <Route path="/supervisor" element={<Supervisor />} /> */}
          {/* <Route path="/assignmenthistory" element={<Assignmenthistory />} /> */}
          {/* <Route path="/reportview" element={<ReportsView />} /> */}
          <Route path="*" element={<Notfound />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
