import React from "react";
import { Grid } from "@material-ui/core";

import "./login.css";
import logo from "../assets/images/Protrac-white-logo.svg";
import { LinearProgress } from "@mui/material";

export default function Loading2() {
  return (
    <>
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="bg-color"
        >
          <Grid item xs={12} md={3}>
            <div className="login-container" boxShadow={3}>
              <div className="logo">
                <img src={logo} alt="protrac" />
              </div>
              <LinearProgress
                style={{
                  background: "white",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
