export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const SUPERVISOR_LOGIN = "SUPERVISOR_LOGIN";
export const SUPERVISOR_LOGOUT = "SUPERVISOR_LOGOUT";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_RESPONSIVE_SIDEBAR = "TOGGLE_RESPONSIVE_SIDEBAR";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const GET_ACTIVITY_ERROR = "GET_ACTIVITY_ERROR";
export const GET_TASK = "GET_TASK";
export const GET_ALL_TASK = "GET_ALL_TASK";
export const GET_TASK_ERROR = "GET_TASK_ERROR";
export const UPDATE_NEW_TASK = "UPDATE_NEW_TASK";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const GET_STATE = "GET_STATE";
export const GET_CITY = "GET_CITY";

export const GET_PROJECT_LIST = "GET_PROJECT_LIST";
export const FILTER_ACTIVITY = "FILTER_ACTIVITY";
export const FILTER_TASK = "FILTER_TASK";
