import {
  ADMIN_LOGIN,
  TOGGLE_SIDEBAR,
  SUPERVISOR_LOGIN,
  ADMIN_LOGOUT,
  SUPERVISOR_LOGOUT,
  TOGGLE_RESPONSIVE_SIDEBAR,
} from "../constants/index";

const INITIAL_STATE = {
  info: {},
  sidebarOpen: false,
  responsiveSidebar: true,
  role: "",
  isLogin: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADMIN_LOGIN:
      return {
        ...state,
        info: action.payload.info,
        role: action.payload.role,
        isLogin: true,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        info: {},
        role: "",
        isLogin: false,
      };
    case SUPERVISOR_LOGIN:
      return {
        ...state,
        info: action.payload,
        role: action.payload.role,
        isLogin: true,
      };
    case SUPERVISOR_LOGOUT:
      return {
        ...state,
        info: {},
        role: "",
        isLogin: false,
      };
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
      };
    case TOGGLE_RESPONSIVE_SIDEBAR:
      return {
        ...state,
        responsiveSidebar: !state.responsiveSidebar,
      };

    default:
      return state;
  }
}
